import {
  configureStore, ThunkAction, Action, combineReducers,
} from '@reduxjs/toolkit';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import { isSSR } from 'utils';
import { api as apiCommon } from '../generated/schemas/common';
import { orders } from './features/orders';
import { offers } from './features/offers';
import { teeOffers } from './features/teeOffers';
import { providers } from './features/providers';
import { eventsLog } from './features/eventsLog';
import { orderDetails } from './features/orderDetails';
import { sdk, getBroatcastWhiteList as getBroatcastWhiteListSdk } from './features/sdk';
import { notifications, getBroatcastWhiteList as getBroatcastWhiteListNotifications } from './features/notifications';
import { getReducer as getReducerAlerts, getBroatcastWhiteList as getBroatcastWhiteListAlerts, alerts } from './features/alerts';
import { getReducer as getReducerWallet, getBroatcastWhiteList as getBroatcastWhiteListWallet, wallet } from './features/wallet';
import { getReducer as getReducerTableSettings, tableSettings } from './features/tableSettings';
import { getReducer as getReducerAnnouncements, announcements } from './features/announcements';
import { getReducer as getReducerGlobalSession, globalSession } from './features/globalSession';
import { spProvider } from './features/spProvider';
import { api as spProviderApi } from './features/spProvider/api';
import { api as serverApi } from './features/server/api';
import {
  getBroatcastWhiteList as getBroatcastWhiteListCreateOrderV2, createOrderV2, getReducer as getReducerCreateOrderV2,
} from './features/createOrderV2';
import {
  getBroatcastWhiteList as getBroatcastWhiteListordersSortingMethods, ordersSortingMethods,
} from './features/ordersSortingMethods';
import { getReducer as getReducerTheme, getBroatcastWhiteList as getBroatcastWhiteListTheme, theme } from './features/theme';
import { getReducer as getReducerSystem, getBroatcastWhiteList as getBroatcastWhiteListSystem, system } from './features/system';
import { api as oAuth2Api } from './features/authOAuth2/api';
import {
  getReducer as getReducerSecretKeeper,
  getBroatcastWhiteList as getBroatcastWhiteListSecretKeeper,
  secretKeeper,
} from './features/secretKeeper';
import {
  getReducer as getReducerAuthOAuth2,
  getBroatcastWhiteList as getBroatcastWhiteListAuthOAuth2,
  authOAuth2,
} from './features/authOAuth2';
import { api as secretKeeperApi } from './features/secretKeeper/api';
import {
  getBroatcastWhiteList as getBroatcastWhiteListShopwindow,
  shopwindow,
} from './features/shopwindow';
import { events } from './features/events';
import { windowSize } from './features/windowSize';
import { modals } from './features/modals';
import {
  createCookieStorage, createLocalStorage, createNoopStorage, createSessionStorage,
} from './features/helpers';
import { Storages, Storage } from './features/types';

// use broadcast api only for required reducers
const getStateSyncMiddleware = () => createStateSyncMiddleware({
  prepareState: (state) => state.toJS(),
  receiveState: (_, nextState) => nextState,
  whitelist: [
    ...getBroatcastWhiteListTheme(),
    ...getBroatcastWhiteListShopwindow(),
    ...getBroatcastWhiteListWallet(),
    ...getBroatcastWhiteListAlerts(),
    ...getBroatcastWhiteListNotifications(),
    ...getBroatcastWhiteListSdk(),
    ...getBroatcastWhiteListordersSortingMethods(),
    ...getBroatcastWhiteListSystem(),
    ...getBroatcastWhiteListAuthOAuth2(),
    ...getBroatcastWhiteListSecretKeeper(),
    ...getBroatcastWhiteListCreateOrderV2(),
  ],
});

export const storages = {
  [wallet.name]: Storages.localStorage,
  [tableSettings.name]: Storages.localStorage,
  [announcements.name]: Storages.localStorage,
  [theme.name]: Storages.cookie,
  [alerts.name]: Storages.localStorage,
  [globalSession.name]: Storages.sessionStorage,
  [ordersSortingMethods.name]: Storages.localStorage,
  [system.name]: Storages.localStorage,
  [authOAuth2.name]: Storages.localStorage,
  [secretKeeper.name]: Storages.localStorage,
  [createOrderV2.name]: Storages.localStorage,
};

export const getStorage = (
  reducerName: keyof typeof storages | undefined,
  cookies?: ReadonlyRequestCookies,
): Storage => {
  const storage = storages[reducerName as keyof typeof storages];
  switch (storage) {
    case Storages.localStorage:
      return createLocalStorage();
    case Storages.cookie:
      return createCookieStorage(cookies);
    case Storages.sessionStorage:
      return createSessionStorage();
    default:
      return createNoopStorage();
  }
};

const getRootReducer = (cookies?: ReadonlyRequestCookies) => combineReducers({
  [offers.reducerPath]: offers.reducer,
  [teeOffers.reducerPath]: teeOffers.reducer,
  [providers.reducerPath]: providers.reducer,
  [orders.reducerPath]: orders.reducer,
  [apiCommon.reducerPath]: apiCommon.reducer,
  [eventsLog.reducerPath]: eventsLog.reducer,
  [oAuth2Api.reducerPath]: oAuth2Api.reducer,
  [secretKeeperApi.reducerPath]: secretKeeperApi.reducer,
  [spProviderApi.reducerPath]: spProviderApi.reducer,
  [serverApi.reducerPath]: serverApi.reducer,
  [orderDetails.name]: orderDetails.reducer,
  [sdk.name]: sdk.reducer,
  [notifications.name]: notifications.reducer,
  [windowSize.name]: windowSize.reducer,
  [modals.name]: modals.reducer,
  [createOrderV2.name]: getReducerCreateOrderV2(getStorage(createOrderV2.name)),
  [wallet.name]: getReducerWallet(getStorage(wallet.name)),
  [tableSettings.name]: getReducerTableSettings(getStorage(tableSettings.name)),
  [announcements.name]: getReducerAnnouncements(getStorage(announcements.name)),
  [theme.name]: getReducerTheme(getStorage(theme.name, cookies)),
  [globalSession.name]: getReducerGlobalSession(getStorage(globalSession.name)),
  [events.name]: events.reducer,
  [shopwindow.name]: shopwindow.reducer,
  [alerts.name]: getReducerAlerts(getStorage(alerts.name)),
  [ordersSortingMethods.name]: ordersSortingMethods.reducer,
  [system.name]: getReducerSystem(getStorage(system.name)),
  [authOAuth2.name]: getReducerAuthOAuth2(getStorage(authOAuth2.name)),
  [secretKeeper.name]: getReducerSecretKeeper(getStorage(secretKeeper.name)),
  [spProvider.name]: spProvider.reducer,
});

export const makeStore = (
  props?: CreateStoreProps,
) => {
  const {
    cb, preloadedState, cookies,
  } = props || {};
  const store = configureStore({
    reducer: getRootReducer(cookies),
    devTools: process.env.NODE_ENV === 'development',
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          `${createOrderV2.name}/updateProcess`, // ignore serialize for Error instance
        ],
        ignoredPaths: [`${createOrderV2.name}.process`], // ignore serialize for Error instance
      },
    })
      .concat(!isSSR() ? [getStateSyncMiddleware()] : [])
      .prepend(
        teeOffers.middleware,
        offers.middleware,
        providers.middleware,
        orders.middleware,
        eventsLog.middleware,
        oAuth2Api.middleware,
        secretKeeperApi.middleware,
        spProviderApi.middleware,
        serverApi.middleware,
        apiCommon.middleware as any, // todo add types
      ),
  });
  const persistor = persistStore(store, {}, () => {
    cb?.(store);
  });
  (store as any).__persistor = persistor;
  if (!isSSR()) {
    initMessageListener(store);
  }

  return store;
};

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export interface CreateStoreProps {
  cb?: (store: any) => void; // AppStore
  preloadedState?: any; // AppStore
  cookies?: ReadonlyRequestCookies;
 }

// wait for rehydration to finish
export const createStoreServer = async (props?: CreateStoreProps): Promise<AppStore> => {
  return new Promise((resolve) => {
    const store = makeStore({
      ...props,
      cb: resolve,
    });
    (store as any).__persistor.pause();
  });
};

export const createStoreClient = (props?: CreateStoreProps) => {
  'use client';

  const store = makeStore(props);
  if (isSSR()) {
    (store as any).__persistor.pause();
  }
  return store;
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
